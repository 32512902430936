const IMPACT_ACTIONS: { title: string; description: string; image: string; link: string }[] = [
  {
    title: 'Pré-inscrição na Campanha U.DREAM 2024',
    description:
      'No Sábado dia 25 de Maio vamos sair às ruas do Porto e de Braga para levar um sorriso às pessoas por quem passamos, seja com serenatas, com mensagens de bom dia, com pequenas ofertas ou abraços. Procura no nossa canal de youtube algumas das nossas ações e deixa-te surpreender. Juntas-te a nós?',
    image: 'homepage/impact-actions/campanha.jpg',
    link: 'https://forms.gle/N3BZKzc8bYox2GJP9',
  },
  {
    title: 'Pré-inscrição no Impact Day U.DREAM 2024',
    description:
      'No dia 13 de Julho, vamos preparar uma manhã dedicada a mergulhar nos desafios sociais da cidade do Porto. Só terás de escolher um público alvo e estar às 9h30 no ponto de encontro que será nos escritórios da U.DREAM no Porto. Quanto ao resto, não precisas de te preocupar, nós preparamos tudo, não esqueças só do teu melhor sorriso. A ação tem a duração máxima de 3 horas.',
    image: 'homepage/impact-actions/impact-day.jpg',
    link: 'https://forms.gle/N3BZKzc8bYox2GJP9',
  },
];

const OPINION_LEADERS: { id: string; name: string; storageImageUrl: string; imageUrl?: string }[] = [
  {
    id: 'opl#1cl3m15',
    name: 'Diogo Cruz',
    storageImageUrl: '/databases/opinion-leaders/opl#1cl3m15.jpg',
  },
  {
    id: 'opl#jo6au1d',
    name: 'Diogo Varandas',
    storageImageUrl: '/databases/opinion-leaders/opl#jo6au1d.jpg',
  },
  {
    id: 'opl#0jxe8yb',
    name: 'Pedro Almeida',
    storageImageUrl: '/databases/opinion-leaders/opl#0jxe8yb.jpg',
  },
  {
    id: 'opl#huohlv5',
    name: 'Joana Moreira',
    storageImageUrl: '/databases/opinion-leaders/opl#huohlv5.jpg',
  },
  {
    id: 'opl#3s1vkwt',
    name: 'Mafalda Sarmento',
    storageImageUrl: '/databases/opinion-leaders/opl#3s1vkwt.jpg',
  },
  {
    id: 'opl#9g9x586',
    name: 'Lokas Cruz - Ana Paula Cruz',
    storageImageUrl: '/databases/opinion-leaders/opl#9g9x586.jpg',
  },
];

const STATIC_DATA = {
  IMPACT_ACTIONS,
  OPINION_LEADERS,
};

export default STATIC_DATA;
